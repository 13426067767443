//@ts-check
import React from "react"
import Layout from "../components/App/Layout"
import AuthWrapper from "../components/AuthWrapper"

import Navbar from "../components/LinkedInPowertools/NavbarLIP"
import Footer from "../components/App/Footer"
import ConfirmEmailForm from "../components/ProfileAuthentication/ConfirmEmailForm"

const ConfirmEmail = () => {
  return (
    <AuthWrapper>
      <Layout>
        <Navbar />

        <section className="profile-authentication-area ptb-100">
          <div className="container">
            <h2>Confirm Email</h2>

            <div className="row">
              <div className="col-lg-6 col-md-12">
                <ConfirmEmailForm />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </Layout>
    </AuthWrapper>
  )
}

export default ConfirmEmail
