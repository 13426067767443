//@ts-check
import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";

import { Button, Checkbox, Form } from "antd";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../context/AuthContext";
import { isBrowser } from "../../utils/SSR";
import API from "../../utils/API";
import { getCookie } from "../../utils/cookies";

const ConfirmEmailForm = () => {
  const authContext = useContext(AuthContext);
  const [error, setError] = useState("");

  const confirmSubmit = async ({ email, code, remember }) => {
    try {
      await Auth.confirmSignUp(email.toLowerCase(), code);

      if (remember) {
        isBrowser() &&
          window.localStorage.setItem("savedEmailLogin", email.toLowerCase());
      } else {
        isBrowser() && window.localStorage.setItem("savedEmailLogin", "");
      }

      setTimeout(() => {
        authContext.assessLoggedInState();
      }, 3000);
    } catch (e) {
      setError("Unable to log in with that email and password");
    }
  };

  const createCheckoutSession = async membershipLevel => {
    try {
      const res = await API.post({
        path: "create-checkout-session",
        data: {
          membershipLevel,
        },
      });
      console.log({ checkoutUrl: res.redirectUrl });

      if (res.redirectUrl) {
        window.location.replace(res.redirectUrl);
      }
    } catch (error) {
      console.log("error making request to check session api");
      console.log(error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const membershipLevel = urlParams.get("level");
    const referralCookie = isBrowser() && getCookie("referral");
    console.log({ membershipLevel, referralCookie });

    if (authContext.isLoggedIn && referralCookie) {
      addReferral(referralCookie);
    }

    if (authContext.isLoggedIn && membershipLevel) {
      createCheckoutSession(membershipLevel);
      return;
    }
    if (authContext.isLoggedIn) {
      navigate("/my-account");
    }
  }, [authContext.isLoggedIn]);

  const loginSubmitFailed = () => {
    console.log("form submit failed");
  };

  const email =
    (isBrowser() && window.localStorage.getItem("confirmEmail")) || "";

  return (
    <div className="login-form">
      <div style={{ color: "black", paddingBottom: "10px" }}>
        We've sent you an email with a login code. Enter the code to confirm
        your email address.
      </div>

      <Form
        name="basic"
        wrapperCol={{ span: 24 }}
        onFinish={confirmSubmit}
        onFinishFailed={loginSubmitFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Email"
          className="form-group"
          name="email"
          initialValue={email || ""}
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            { required: true, message: "Please input your email!" },
          ]}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Email Address"
            onChange={() => setError("")}
          />
        </Form.Item>

        <Form.Item
          //label="Email"
          className="form-group"
          label="Code"
          name="code"
          rules={[
            {
              required: true,
              message: "Please input the code sent to your email address",
            },
          ]}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Code"
            onChange={() => setError("")}
          />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <div className="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
            <Checkbox>Remember me</Checkbox>
          </div>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" style={{ height: "50px" }}>
            Log In
          </Button>
        </Form.Item>
        {error && <div>{error}</div>}
      </Form>
    </div>
  );
};

export default ConfirmEmailForm;

const addReferral = async referralCookie => {
  try {
    const res = await API.post({
      path: "add-referral",
      data: {
        referralCode: referralCookie,
      },
    });
  } catch (error) {
    console.log("error making request to check session api");
    console.log(error);
  }
};
